let punctuationString = '--—，。《》…【】“”！？?,.、·：[]""!；; 　0123456789';
var punctuationDict = {};

punctuationString.split('').map(punctuation => {
  punctuationDict[punctuation] = true;
});

function isPunctuation(string) {
  if (string.length == 1) {
    return string in punctuationDict;
  } else {
    for (var i=0; i<string.length; i++) {
      if (!(string[i] in punctuationDict)) {
        return false;
      }
    }
    return true;
  }
}

export default isPunctuation;
