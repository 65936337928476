import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

const wordStyles = theme => ({
  grid: {
    display: 'grid',
    gridAutoColumns: 'auto',
    gridTemplateRows: 'auto auto',
    alignItems: 'end',
    "&:hover $chinese": {
      backgroundColor: theme.palette.primary[50],
    },
  },
  chinese: {
    fontSize: '40px',
    gridRow: '2',
  },
  pronunciation: {
    fontSize: '12px',
    marginBottom: '4px',
    height: '14px',
    gridRow: '1',
  },
  fullWidth: {
    width: '100%',
    height: '24px',
  },
});

const Word = withStyles(wordStyles)(class Word extends PureComponent {
  state = {
    pronunciation: null,
  }

  onClick = () => {
    this.props.onClick && this.props.onClick();
  }


  render() {
    let classes = this.props.classes;
    let divClassName = `${this.props.className} ${classes.grid}`;

    var characterDivs = [];
    if (this.props.word !== undefined) {
      for (var i=0; i<this.props.word.length; i++) {
        characterDivs.push(
          <div key={'c'+i} className={classes.chinese} onClick={this.onClick}>
            {this.props.word[i]}
          </div>
        )
      }
    }


    if (this.props.pronunciation !== undefined) {
      var pronunciationDivs = [];
      for (var i=0; i<this.props.pronunciation.length; i++) {
        characterDivs.push(
          <div key={'p'+i} className={classes.pronunciation}>
            {this.props.pronunciation[i]}
          </div>
        )
      }
    }

    if (this.props.word === '\n') {
      return <div className={classes.fullWidth} />
    }

    return (
      <div
        className={divClassName}
        >
        {characterDivs}
        {pronunciationDivs}
      </div>
    )
  }
});

export default Word;
