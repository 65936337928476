import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import firebase from 'firebase';

import DatabaseTextField from '../widgets/DatabaseTextField';

require('firebase/firestore')



const styles = theme => ({
  container: {
    maxWidth: '800px',
    width: 'calc(100% - 16px)',
    paddingTop: 'calc(64px)', // header is 64px
    paddingBottom: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '200px auto',
    alignItems: 'center',
    gridRowGap: '1px',
    gridColumnGap: '1px',
  },
  grow: {
    flexGrow: 1,
  },
});

class DocsPage extends Component {
  state = {
    documents: [],
    documentData: {},
    userDocumentData: {},
  }

  componentDidMount() {
    this.loadDocuments();
  }

  componentWillUpdate(nextProps, nextState) {
  }

  loadDocuments = () => {
    this.setState({
      documents: [],
      documentData: null,
      currentFileName: null,
    });

    let linesRef = firebase.firestore()
                .collection('files').orderBy('filename').get();
    linesRef.then(querySnapshot => {
      var documents = [];
      var documentData = {};

      querySnapshot.forEach(doc => {
        documents.push(doc.id);
        documentData[doc.id] = doc.data();
        // console.log(doc.id, " => ", doc.data());
      });

      this.setState({
        documents: documents,
        documentData: documentData,
      });
    });
  }

  getLink = document => {
    return `/d/${document}/p`
  }

  render() {
    const classes = this.props.classes;

    var gridItems = [];

    gridItems.push(<div key={`p`}>Document</div>)
    gridItems.push(<div key={`n`}>Notes</div>)

    this.state.documents.map(document => {

      var ref = null;

      gridItems.push(<div key={`p${document}`}><a href={this.getLink(document)}>{document}</a></div>)

      if (firebase.auth().currentUser !== null) {
        ref = firebase.firestore()
          .collection('users').doc(firebase.auth().currentUser.uid)
          .collection('documentNotes').doc(document)

          gridItems.push(
            <DatabaseTextField
              key={`n${document}`}
              refPath={ref.path}
              margin="dense"
             />
          )
      } else {
        gridItems.push(<div key={`n${document}`}></div>)
      }

    })

    return (
      <div>
        <Helmet title={`Dokibo BookPrep`} />
        <AppBar color='default' position="fixed">
          <Toolbar>
            <Typography type="title" color="inherit" className={classes.grow}>
              Documents
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <div className={classes.grid}>
            {gridItems}
          </div>
        </div>
      </div>
    );
  }
}


DocsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocsPage);
