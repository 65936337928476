import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';


import firebase from 'firebase';

import DatabaseTextField from '../widgets/DatabaseTextField';
import LearnedWordWatcher from '../widgets/LearnedWordWatcher';

require('firebase/firestore')



const styles = theme => ({
  container: {
    maxWidth: '800px',
    width: 'calc(100% - 16px)',
    paddingTop: 'calc(64px)', // header is 64px
    paddingBottom: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '80px 55px 55px auto',
    alignItems: 'center',
    gridRowGap: '1px',
    gridColumnGap: '1px',
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 288,
  },
    menu: {
    width: 288,
  },
});

class DocParagraphsPage extends Component {
  state = {
    fileNames: [],
    paragraphs: [],
    paragraphData: {},
    userParagraphData: {},
    signedIn: false,
  }

  componentDidMount() {
    console.log("docId:", this.props.match.params.docId)
    this.loadFile(this.props.match.params.docId);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.watchUserProgress(user.uid);
        // User is signed in.
      } else {
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillUpdate(nextProps, nextState) {
  }

  loadFile = (fileName) => {
    console.log(`setting file to "${fileName}""`);
    this.setState({
      paragraphs: [],
      paragraphData: null,
      currentFileName: null,
    });

    let linesRef = firebase.firestore()
                .collection('files').doc(fileName)
                .collection('lines').orderBy('line').get();
    linesRef.then(querySnapshot => {
      var paragraphs = [];
      var paragraphData = {};

      querySnapshot.forEach(doc => {
        paragraphs.push(doc.id);
        paragraphData[doc.id] = doc.data();
        // console.log(doc.id, " => ", doc.data());
      });

      this.setState({
        paragraphs: paragraphs,
        paragraphData: paragraphData,
        currentFileName: fileName,
      });

      if (!firebase.auth().currentUser.uid) {
        console.warn("user isn't logged in");
        return;
      }
    });
  }

  watchUserProgress = userId => {
    let linesRef = firebase.firestore()
                .collection('users').doc(userId)
                .collection('files').doc(this.props.match.params.docId)
                .collection('lines').get();
    linesRef.then(querySnapshot => {
      var userParagraphData = {};

      querySnapshot.forEach(doc => {
        userParagraphData[doc.id] = doc.data();
        // console.log(doc.id, " => ", doc.data());
      });

      console.log(userParagraphData);

      this.setState({
        userParagraphData: userParagraphData,
      });
    });
  }

  getSummary = (paragraph) => {
    var summary = "";
    var summaryLength = 6;
    var words = this.state.paragraphData[paragraph].words;
    for (var i=0; i<words.length; i++) {
      var word = words[i].word;
      if (summary.length > summaryLength) {
        break;
      }
      for (var j=0; j<word.length; j++) {
        var character = word[j];
        if (summary.length > summaryLength) {
          break;
        }
        summary += character;
      }
    }

    if (this.state.paragraphData[paragraph].words.length > summaryLength) {
      summary += "...";
    }

    return summary;
  }

  wordCount = paragraph => {
    return this.state.paragraphData[paragraph].words.length;
  }

  paragraphStatus = paragraph => {
    if (paragraph in this.state.userParagraphData) {
      console.log(this.state.userParagraphData[paragraph]);
      if ('endTime' in this.state.userParagraphData[paragraph]) {
        return 'done'
      } else if ('endTime' in this.state.userParagraphData[paragraph]) {
        return 'started'
      } else {
        return 'started'
      }
    } else {
      return '';
    }
  }

  getLink = paragraph => {
    return `/d/${this.props.match.params.docId}/p/${paragraph}`
  }

  render() {
    const classes = this.props.classes;

    var gridItems = [];

    gridItems.push(<div key={`p`}>Paragraph</div>)
    gridItems.push(<div key={`done`}>Status</div>)
    gridItems.push(<div key={`wc`}>Words</div>)
    gridItems.push(<div key={`n`}>Notes</div>)

    this.state.paragraphs.map(paragraph => {

      var ref = null;

      gridItems.push(<div key={`p${paragraph}`}><a href={this.getLink(paragraph)}>{paragraph}</a></div>)
      gridItems.push(<div key={`done${paragraph}`}>{this.paragraphStatus(paragraph)}</div>)
      gridItems.push(<div key={`wc${paragraph}`}>{this.wordCount(paragraph)}</div>)

      if (firebase.auth().currentUser.uid) {
        ref = firebase.firestore()
          .collection('users').doc(firebase.auth().currentUser.uid)
          .collection('files').doc(this.props.match.params.docId)
          .collection('paragraphNotes').doc(paragraph)

          gridItems.push(
            <DatabaseTextField
              key={`n${paragraph}`}
              refPath={ref.path}
              margin="dense"
             />
          )
      } else {
        gridItems.push(<div key={`n${paragraph}`}></div>)
      }

    })

    return (
      <div>
        <Helmet title={`Dokibo BookPrep`} />
        <AppBar color='default' position="fixed">
          <Toolbar>
            <Typography type="title" color="inherit" className={classes.grow}>
              Paragraphs
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <div className={classes.grid}>
            {gridItems}
          </div>
        </div>
      </div>
    );
  }
}


DocParagraphsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocParagraphsPage);
