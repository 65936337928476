import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';


import firebase from 'firebase';
require('firebase/firestore');


var milestones = {
  // words
  'correctPronunciationFirstGuess': 'You got the pronunciation for {word} on the first try!',
  'correctPronunciationStreak': 'You\'re on a streak! You\'ve gotten {word} correct the last {streakLength} guesses!',
  'correctAfterIncorrect': "Progress! You've guessed {word} wrong before",

  // documents (success)
  'nPronunciations': "You've learned {pronunciationsKnown} pronunciations!",
  'nDefinitions': "You've learned {definitionsKnown} definitions!",
  'nTypesOfXSeen': "You've seen {n} types of {x}",
  'nPercentWordsSeen': "You've seen {n}% of words in the document!",
  'nPercentWordsPronunciationKnown': "You know the pronunciation for {n}% of words in the document!",
  'nPercentWordsDefinitionKnown': "You know the definition for {n}% of words in the document!",

  // documents (warning)
  'confuseXToneWithY': "Ouch, you often confuse X tone for Y tone with this word.",
  'confuseXInitialWithY': "Ouch, you often confuse X initial for Y initial with this word.",
  'confuseXFinalWithY': "Ouch, you often confuse X final for Y final with this word.",
}

const styles = theme => ({
})

const LearnedWordWatcher = withStyles(styles)(class extends Component {
  state = {
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupDatabaseListener(user.uid)
        // User is signed in.
      } else {
        console.log("user signing out");
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {

  }

  componentWillUnmount() {
  }

  setupDatabaseListener = (userId) => {
    const { enqueueSnackbar } = this.props;

    console.log("setting up milestones watcher", `/users/${userId}/milestones`);
    this.firstLoadMilestones = false;
    this.documentCache = {};
    this.unsubscribeMilestoneWatcher && this.unsubscribeMilestoneWatcher();
    this.unsubscribeMilestoneWatcher = firebase.firestore()
      .collection('users').doc(userId)
      .collection('milestones').where('timestamp', '>=', new Date())
      .onSnapshot(snapshot => {
        console.log("new milestone(s)", snapshot.docChanges());
        if (this.firstLoadMilestones) {
          this.firstLoadMilestones = false;
          return;
        }
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            var milestoneText = milestones[change.doc.get('type')];

            var re = /\{([^}]*)\}/g;
            var match;

            do {
              match = re.exec(milestoneText);
              // console.log('match', match);
              if (match) {
                milestoneText = milestoneText.replace(`{${match[1]}}`, change.doc.get(match[1]));
                // console.log('milestoneText', milestoneText);
              }
            } while (match);

            enqueueSnackbar(milestoneText, {variant: change.doc.get('variant')});
          }
        });
    });

    this.firstLoadDefinition = true;
    this.unsubscribeDefinitionKnownFunction && this.unsubscribeDefinitionKnownFunction();
    this.unsubscribeDefinitionKnownFunction = firebase.firestore()
      .collection('users').doc(userId)
      .collection('words').where('definitionKnown', '>=', 0.8)
      .onSnapshot(snapshot => {
        if (this.firstLoadDefinition) {
          this.firstLoadDefinition = false;
          return;
        }
        snapshot.docChanges().forEach(change => {
          if (change.type === "added" && change.doc.get('pronunciationKnown') >= 0.8) {
            enqueueSnackbar(`You seem to know ${change.doc.id}, so we'll stop showing it`, {variant: 'info'});
          }
        });
    });
  }

  render() {
    return (
      <div />
    )
  }
});

export default withSnackbar(LearnedWordWatcher);
