import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import BookPrepPage from './pages/BookPrepPage';
import DocParagraphsPage from './pages/DocParagraphsPage';
import SignInPage from './pages/SignInPage';
import DocsPage from './pages/DocsPage';

import firebase from 'firebase';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: green,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 20,
  }
});

console.log(theme);

class PrivateRoute extends Component {
  state = {}

  componentWillMount() {
    this.deregisterFirebaseAuthListener = firebase.auth().onAuthStateChanged(this.setup.bind(this));
  }

  componentWillUnmount() {
    this.deregisterFirebaseAuthListener && this.deregisterFirebaseAuthListener();
  }

  setup(user) {
    this.setState({signedIn: user !== null });
  }

  render() {
    const { component: Component, props, ...rest } = this.props
    if (this.state.signedIn === undefined) {
      return null;
    } else {
      return (
        <Route
          {...rest}
          render={props =>
            this.state.signedIn ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
    }
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/login" component={SignInPage}/>
            <PrivateRoute path="/d/:docId/p/:paragraphId" component={BookPrepPage}/>
            <PrivateRoute path="/d/:docId/p" component={DocParagraphsPage}/>
            <PrivateRoute path="/d" component={DocsPage}/>
            <PrivateRoute path="/" component={DocsPage}/>
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
