import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import firebase from 'firebase';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';



var config = {
  apiKey: "AIzaSyBzG6DZ2wBU_GW2l73Xa9mNScpfB248pto",
  authDomain: "dokibo-prod.firebaseapp.com",
  databaseURL: "https://dokibo-prod.firebaseio.com",
  projectId: "dokibo-prod",
  storageBucket: "dokibo-prod.appspot.com",
  messagingSenderId: "1081707694384"
};

var devConfig = {
  apiKey: "AIzaSyDrx70Xrk896vaucqC81uFPEu7fwqCh2H0",
  authDomain: "dokibo-dev.firebaseapp.com",
  databaseURL: "https://dokibo-dev.firebaseio.com",
  projectId: "dokibo-dev",
  storageBucket: "dokibo-dev.appspot.com",
  messagingSenderId: "357654922849"
};

firebase.initializeApp(devConfig);

global.firebase = firebase;

firebase.auth().onAuthStateChanged((user) => {
  if (user === null) {
    return;
  }

  let ref = firebase.database().ref('users').child(user.uid);

  var updates = {};
  updates['/publicInfo/displayName'] = user.displayName;
  updates['/publicInfo/photoURL'] = user.photoURL;
  updates['/modifiedAt'] = firebase.database.ServerValue.TIMESTAMP;
  updates['/email'] = user.email;
  updates['/providerData'] = user.providerData;
  updates['/providerId'] = user.providerId;

  ref.update(updates);

  var userData = {
    publicInfo: {
      displayName: user.displayName,
      photoURL: user.photoURL,
    },
    modifiedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    email: user.email,
    providerData: user.providerData,
    providerId: user.providerId,
  }

  firebase.firestore()
    .collection('users').doc(user.uid)
    .set(userData, {merge: true});

  // window.FS.identify(user.uid);

  // window.analytics.identify(user.uid, {
  //   name: user.displayName,
  //   email: user.email,
  // });
});


ReactDOM.render(
  <SnackbarProvider
    anchorOrigin={{
         vertical: 'top',
         horizontal: 'center',
     }}
    maxSnack={3}>
      <App />
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
