import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
require('firebase/firestore');

const styles = theme => ({
  card: {
    marginBottom: '8px',
  },
})

const ParagraphSummary = withStyles(styles)(class extends Component {
  state = {
    wordIds: {},
    paragraphWords: [],
    definitionRevealedCount: 0,
    pronunciationRevealedCount: 0,
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupDatabaseListener(user.uid, this.props.fileName, this.props.lineNumber)
        // User is signed in.
      } else {
        console.log("user signing out");
        this.teardownDatabaseListener && this.teardownDatabaseListener();
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.lineNumber != this.props.lineNumber) {
      var newState = {}
      Object.keys(this.state).map(item => {
        newState[item] = null;
      });
      newState.wordIds = {};

      this.setState(newState);
      this.setupDatabaseListener(firebase.auth().currentUser.uid, newProps.fileName, newProps.lineNumber);
    }
  }

  componentWillUnmount() {
    this.teardownDatabaseListener && this.teardownDatabaseListener();
  }

  setupDatabaseListener = (userId, fileName, lineNumber) => {
    console.log(`setting up paragraph summary database listener for /${userId}/${fileName}/${lineNumber}`)
    this.teardownDatabaseListener && this.teardownDatabaseListener();
    this.teardownDatabaseListener = firebase.firestore()
      .collection('users').doc(userId)
      .collection('files').doc(fileName)
      .collection('lines').doc(`${lineNumber}`)
      .collection('words')
      .onSnapshot((snapshot) => {
        var definitionRevealedDiff = 0;
        var pronunciationRevealedDiff = 0;
        var addedWordIds = [];
        var removedWordIds = [];
        var newState = {};
        snapshot.docChanges().forEach((change) => {
          var revealedDefinition = change.doc.get('revealedDefinition');
          var revealedPinyin = change.doc.get('revealedPinyin');
          newState[`wordDoc-${change.doc.id}`] = change.doc.data();

          if (change.type === "added") {
              addedWordIds.push(change.doc.id);
              if (revealedDefinition === true) {
                definitionRevealedDiff += 1
              }
              if (revealedPinyin === true) {
                pronunciationRevealedDiff += 1
              }
          }
          if (change.type === "modified") {
            if (revealedDefinition !== this.state[`wordDoc-${change.doc.id}`].revealedDefinition) {
              definitionRevealedDiff += revealedDefinition ? 1 : -1;
            }
            if (revealedPinyin !== this.state[`wordDoc-${change.doc.id}`].revealedPinyin) {
              pronunciationRevealedDiff += revealedPinyin ? 1 : -1;
            }
          }
          if (change.type === "removed") {
            removedWordIds.push(change.doc.id);
            if (revealedDefinition === true) {
              definitionRevealedDiff -= 1;
            }
            if (revealedPinyin === true) {
              pronunciationRevealedDiff -= 1;
            }
          }
        });

        this.setState((prevState, props) => {
          var wordIds = prevState.wordIds;
          for (var i=0; i<addedWordIds.length; i++) {
            wordIds[addedWordIds[i]] = true;
          }
          for (var i=0; i<removedWordIds.length; i++) {
            delete wordIds[removedWordIds[i]];
          }
          newState.wordIds = wordIds;
          newState["definitionRevealedCount"] = prevState["definitionRevealedCount"] + definitionRevealedDiff;
          newState["pronunciationRevealedCount"] = prevState["pronunciationRevealedCount"] + pronunciationRevealedDiff;
          console.log(newState);
          return newState;
        });
    });
  }

  wordSnapshotKey = id => {
    return `wordDoc-${id}`
  }

  recommendedStudyingWords = () => {
    for (let wordId in this.state.wordIds) {
      var wordSnapshot = this.state[this.wordSnapshotKey(wordId)];

    }
  }

  render() {
    let classes = this.props.classes;
    let position = this.state.position;

    var sections = [];
    var sectionText = "";
    var rows = [];

    return (
      <Card className={classes.card}>
       <CardContent>
         <Typography variant="h5" component="h2">
           Paragraph Summary
         </Typography>
         <Typography component="p">
         {this.state.definitionRevealedCount} definitions revealed
         </Typography>
         <Typography component="p">
         {this.state.pronunciationRevealedCount} pronunciations revealed
         </Typography>
         {this.props.startTime && this.props.endTime &&
           <Typography component="p">
           {`${((this.props.endTime-this.props.startTime)/1000/60).toFixed(0)} minutes`}
           </Typography>
         }
       </CardContent>
       <CardActions>
         <Button size="small">Review</Button>
         <Button size="small">Add to Pleco</Button>
       </CardActions>
      </Card>
    );
  }
});

export default ParagraphSummary;
