import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
require('firebase/firestore');

const pinyinSplit = require('pinyin-split');
const pinyinUtils = require('pinyin-utils');

const styles = theme => ({

})

const HighFrequencyReview = withStyles(styles)(class extends Component {
  state = {

  }

  componentDidMount() {
  }

  componentWillReceiveProps(newProps) {
  }

  componentWillUnmount() {
  }


  render() {
    let classes = this.props.classes;

    console.log('hfw', this.props)
    var rows = [];
    for (let word in this.props.documentWordBundles) {
      //this.props.documentWordBundles[word]
      let bundle = this.props.documentWordBundles[word];
      let split = pinyinSplit(bundle.pinyin.toLowerCase());
      var pinyin = split.map(text => pinyinUtils.markToNumber(text));

      rows.push(
        <div key={word}>
          <Typography variant="h6">{word} {pinyin}</Typography>
          <Typography component="p">{bundle.count} occurances in this doc</Typography>
          <Typography component="p">{bundle.translationWithPOS}</Typography>
        </div>
      )
    }

    return (
      <div>
        <Typography variant="h5" component="h2">Review Common Words</Typography>
        {rows.length == 0 &&
          <Typography component="p">You didn't miss any common words, congrats!</Typography>
        }
        {rows}
      </div>
    );
  }
});

export default HighFrequencyReview;
