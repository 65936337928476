import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';


import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import Translate from '@material-ui/icons/Translate';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  correctTextField: {
    backgroundColor: `${green[50]} !important`,
  },
  incorrectTextField: {
    backgroundColor: `${red[50]} !important`,
  },
})

const WordGuesserView = withStyles(styles)(class extends PureComponent {
  handleTextChange = (event) => {
    if (this.props.r !== undefined) {
      this.props.handleTextChange(this.props.r, event);
    }
  }

  handleBlur = () => {
    if (this.props.r !== undefined) {
      this.props.handleBlur(this.props.r);
    }
  }

  onKeyPress = (event) => {
    if (this.props.r !== undefined) {
        this.props.onKeyPress(this.props.r, event);
    }
  }

  setRef = (event) => {
    if (this.props.r !== undefined) {
      this.props.setRef(this.props.r, event);
    }
  }

  handleClickShowDefinition = () => {
    if (this.props.r !== undefined) {
      this.props.handleClickShowDefinition(this.props.r);
    }
  }

  handleClickShowPronunciation = () => {
    if (this.props.r !== undefined) {
      this.props.handleClickShowPronunciation(this.props.r);
    }
  }

  render() {
    let classes = this.props.classes;
    var label = this.props.word + ' ';
    if (this.props.pinyinIfRevaled) {
      label += this.props.pinyinIfRevaled.join('')
    } else if (this.props.hint) {
      label += this.props.hint;
    }

    return (
      <TextField
        label={label}
        className={classes.textField}
        value={this.props.guessPinyin || ""}
        onChange={this.handleTextChange}
        onBlur={this.handleBlur}
        onKeyPress={this.onKeyPress}
        inputRef={this.setRef}
        margin="normal"
        variant="filled"
        placeholder={`type the pinyin for ${this.props.word}`}
        helperText={this.props.definitionIfShown}
        inputProps={{
          autoCapitalize:"none"
        }}
        InputProps={{
          classes: {
            root: this.props.backgroundClass,
          },
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Show Definition"
                onClick={this.handleClickShowDefinition}
              >
                <Translate />
              </IconButton>
              <IconButton
                aria-label="Show Pronunciation"
                onClick={this.handleClickShowPronunciation}
              >
                <Visibility />
              </IconButton>
            </InputAdornment>
            ,
        }}
      />
    );
  }
});

export default WordGuesserView;
