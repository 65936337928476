import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import guid from '../guid.js';

import firebase from 'firebase';
require('firebase/firestore');

const styles = theme => ({
})

const DatabaseTextField = withStyles(styles)(class extends Component {
  state = {
    instanceId: guid(),
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupDatabaseListener(user.uid, this.props.refPath)
        // User is signed in.
      } else {
        console.log("user signing out");
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.refPath !== this.props.refPath) {
      if (this.saveTimer !== undefined) {
        clearTimeout(this.saveTimer);
        delete this.saveTimer;
      }

      if (this.state.textChanged) {
        this.saveText();
      }

      var newState = {}
      Object.keys(this.state).map(item => {
        newState[item] = null;
      });

      this.setState(newState);
      this.setupDatabaseListener(firebase.auth().currentUser.uid, this.props.refPath);
    }
  }

  componentWillUnmount() {
    if (this.saveTimer !== undefined) {
      clearTimeout(this.saveTimer);
      delete this.saveTimer;
    }

    if (this.state.textChanged) {
      this.saveText();
    }
    this.unsubscribeFunction && this.unsubscribeFunction();
  }


  setupDatabaseListener = (userId, refPath) => {
    this.unsubscribeFunction && this.unsubscribeFunction();
    if (refPath === undefined) {
      return;
    }

    console.log(`setting up text database listener for ${refPath}`)
    this.unsubscribeFunction = firebase.firestore()
      .doc(refPath).onSnapshot((snapshot) => {
        var source = snapshot.hasPendingWrites ? "Local" : "Server";
        if (this.state.firstLoadFinished && (source == "Local"
            || this.state.instanceId == snapshot.get('instanceId'))) {
          return;
        }

        this.setState({
          text: snapshot.get('text'),
          firstLoadFinished: true,
        })
    });
  }


  saveText = () => {
    console.log(`saving text`)

    firebase.firestore().doc(this.props.refPath).set({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      text: this.state.text,
      instanceId: this.state.instanceId,
    });
  }

  handleChange = event => {
    var text = event.target.value;

    this.setState({
      text: text,
      textChanged: true,
    })

    if (this.saveTimer === undefined) {
      console.log('setting timeout of 5 seconds');
      this.saveTimer = setTimeout(this.handleSaveTimerTimeout, 5000);
    }
  }

  handleSaveTimerTimeout = () => {
    delete this.saveTimer;
    this.saveText();
    this.setState({
      textChanged: true,
    })
  }

  render() {
    let classes = this.props.classes;

    return (
      <TextField
        label={this.props.label}
        placeholder={this.props.placeholder}
        margin={this.props.margin}
        value={this.state.text || ''}
        onChange={this.handleChange}
        width={this.props.width}
        fullWidth={this.props.fullWidth}
      />
    );
  }
});

export default DatabaseTextField;
