// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';


class SignInScreen extends React.Component {
  state = {
    uiConfig: null,
  }

  componentDidMount() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };

    // Configure FirebaseUI.
    this.setState({
      uiConfig: {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: from.pathname,
        // We will display Google and Facebook as auth providers.
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ]
      }
    })
  }

  render() {
    return this.state.uiConfig && (
      <div>
        <h1>Dokibo</h1>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth uiConfig={this.state.uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
}


export default SignInScreen
