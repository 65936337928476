import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import guid from '../guid.js';

import firebase from 'firebase';
require('firebase/firestore');

const styles = theme => ({
})

const UserTranslationTextField = withStyles(styles)(class extends Component {
  state = {
    instanceId: guid(),
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupTranslationDatabaseListener(user.uid, this.props.fileName, this.props.paragraph, this.props.chunkKey)
        // User is signed in.
      } else {
        console.log("user signing out");
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.paragraph != this.props.paragraph ||
        newProps.chunkKey != this.props.chunkKey ||
        this.props.fileName != this.props.fileName) {

      if (this.saveTimer !== undefined) {
        clearTimeout(this.saveTimer);
        delete this.saveTimer;
      }

      if (this.state.translationChanged) {
        this.saveTranslation();
      }

      var newState = {}
      Object.keys(this.state).map(item => {
        newState[item] = null;
      });

      this.setState(newState);
      this.setupTranslationDatabaseListener(firebase.auth().currentUser.uid, newProps.fileName, newProps.paragraph, newProps.chunkKey);
    }
  }

  componentWillUnmount() {
    if (this.saveTimer !== undefined) {
      clearTimeout(this.saveTimer);
      delete this.saveTimer;
    }

    if (this.state.translationChanged) {
      this.saveTranslation();
    }
    this.unsubscribeFunction && this.unsubscribeFunction();
  }


  setupTranslationDatabaseListener = (userId, fileName, paragraph, chunkKey) => {
    console.log(`setting up translation database listener for /${userId}/${fileName}/${paragraph}`)
    this.unsubscribeFunction && this.unsubscribeFunction();
    this.unsubscribeFunction = firebase.firestore()
      .collection('users').doc(userId)
      .collection('files').doc(fileName)
      .collection('lines').doc(`${paragraph}`)
      .collection('chunks').doc(chunkKey)
      .onSnapshot((snapshot) => {
        var source = snapshot.hasPendingWrites ? "Local" : "Server";
        console.log("chunk", chunkKey, snapshot.data(), source);
        if (this.state.firstLoadFinished && (source == "Local"
            || this.state.instanceId == snapshot.get('instanceId'))) {
          return;
        }

        this.setState({
          userTranslation: snapshot.get('userTranslation'),
          firstLoadFinished: true,
        })
    });
  }


  saveTranslation = () => {
    console.log(`saving translation`)

    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }

    let chunkRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('files').doc(this.props.fileName)
      .collection('lines').doc(this.props.paragraph)
      .collection('chunks').doc(this.props.chunkKey)

    chunkRef.set({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      sourceText: this.props.chunkText,
      userTranslation: this.state.userTranslation,
      instanceId: this.state.instanceId,
    });
  }

  handleChange = event => {
    var text = event.target.value;

    this.setState({
      userTranslation: text,
      translationChanged: true,
    })

    if (this.saveTimer === undefined) {
      console.log('setting timeout of 5 seconds');
      this.saveTimer = setTimeout(this.handleSaveTimerTimeout, 5000);
    }
  }

  handleSaveTimerTimeout = () => {
    delete this.saveTimer;
    this.saveTranslation();
    this.setState({
      translationChanged: true,
    })
  }

  render() {
    let classes = this.props.classes;

    return (
      <TextField
        label="Your Translation"
        placeholder="translate the above sentence..."
        multiline
        fullWidth
        rows="4"
        margin="normal"
        value={this.state.userTranslation || ''}
        onChange={this.handleChange}
      />
    );
  }
});

export default UserTranslationTextField;
