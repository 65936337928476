function splitTextAndNumbers(inputText) {
    // returns substrings that are all text or all numbers
    return inputText.replace(/\'/g, '').split(/(\d+)/).filter(Boolean);
}

function splitInitialAndFinal(inputText) {
  var initial = inputText[0] + (inputText[1] === 'h' ? 'h' : '');
  if (initial === 'an' || initial === 'er') {
    initial = '';
  }
  let final = inputText.slice(initial.length);
  return [initial, final];
}

// returns list of list of distances for each initial, final, tone for each character.
function PinyinDis(guess, actual) {
  var guess_split = splitTextAndNumbers(guess);
  var actual_split = splitTextAndNumbers(actual);

  var output = [];

  for (var i=0; i<actual_split.length; i += 2) {
    if (actual_split[i].length < 2) {
      continue
    }
    let actualInitialAndFinal = splitInitialAndFinal(actual_split[i]);
    let actualTone = actual_split[i+1];

    if (i < guess_split.length) {
      if (guess_split[i].length < 2) {
        continue
      }
      let guessInitialAndFinal = splitInitialAndFinal(guess_split[i]);

      if (i+1 < guess_split.length) {
        let guessTone = guess_split[i+1];

        output.push({
          initial: {
            isCorrect: guessInitialAndFinal[0] === actualInitialAndFinal[0],
            guess: guessInitialAndFinal[0],
            actual: actualInitialAndFinal[0],
          },
          final: {
            isCorrect: guessInitialAndFinal[1] === actualInitialAndFinal[1],
            guess: guessInitialAndFinal[1],
            actual: actualInitialAndFinal[1],
          },
          tone: {
            isCorrect: guessTone === actualTone,
            guess: guessTone,
            actual: actualTone,
          },
        })
      }
    }
  }

  return output;
}


function PinyinLevDis(source, target) {
  var source_split = splitTextAndNumbers(source);
  var target_split = splitTextAndNumbers(target);

  console.log(source_split);

  var output = [];

  for (var i=0; i<source_split.length; i += 2) {
    if (i+1 < source_split.length) {
      var w1 = source_split[i] + source_split[i+1];
    } else {
      var w1 = source_split[i];
    }

    if (i+1 < target_split.length) {
      var w2 = target_split[i] + target_split[i+1];
    } else {
      var w2 = target_split[i];
    }

    //output.push([LevDis(w1, w2), w1, w2]);
    output.push(LevDis(w1 || "", w2 || ""));
  }

  return output;
}

function LevDis(s,t) {
  // Workaround on Google Sheets rate-limit for external functions
  //var sleep = Math.floor((Math.random() * 3000) + 1);
  //Utilities.sleep(3000+sleep);

  // The code
  if (s == t) return 0;
  if (s.length == 0) return t.length;
  if (t.length == 0) return s.length;

  var v0 = [];
  var v1 = [];
  var i;
  var j;
  var cost;

  for (i = 0; i < (t.length+1); i++) {
      v0[i] = i;
  }

  for(i = 0; i < s.length; i++)
  {
      v1[0] = i + 1;
      for(j = 0; j < t.length; j++)
      {
        if (s[i] == t[j]) {
          cost = 0;
        } else {
          cost = 1;
        }
        v1[j + 1] = Math.min(v1[j] + 1, v0[j + 1] + 1, v0[j] + cost);
      }
      for(j = 0; j < (t.length+1); j++) {
          v0[j] = v1[j];
      }
  }
  return v1[t.length];
}

export default PinyinLevDis;
export {PinyinDis, PinyinLevDis};
