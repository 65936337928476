import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';

import {PinyinLevDis, PinyinDis} from './levdis.js';
import isPunctuation from './isPunctuation.js';
import HighFrequencyReview from './widgets/HighFrequencyReview.js';
import ParagraphSummary from './widgets/ParagraphSummary.js';
import ParagraphPreview from './widgets/ParagraphPreview.js';
import UserTranslationTextField from './widgets/UserTranslationTextField.js';
import TooltipWord from './widgets/TooltipWord.js';
import WordGuesserView from './widgets/WordGuesserView.js';
import DatabaseTextField from './widgets/DatabaseTextField';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Translate from '@material-ui/icons/Translate';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import firebase from 'firebase';
require('firebase/firestore')

const pinyinSplit = require('pinyin-split');
const pinyinUtils = require('pinyin-utils');


const styles = theme => ({
  reader: {
    display: 'flex',
    flexDirection: 'column',
  },
  readerSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing.unit,
  },
  card: {
    marginBottom: '8px',
  },
  inputInlineButton: {
    marginBottom: '4px',
    marginRight: '4px',
    width: '20px',
    height: '20px',
  },
  correctTextField: {
    backgroundColor: `${green[50]} !important`,
  },
  incorrectTextField: {
    backgroundColor: `${red[50]} !important`,
  },
  sectionSpans: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
  }
});

const Reader = withStyles(styles)(class extends Component {
  state = {
    position: 0,
    focusedRow: null,
    signedIn: false,
    startTime: new Date(),
  }

  componentDidMount() {
    global.dokibo_debug_reader = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupGuessDatabaseListener(user.uid, this.props.fileName, this.props.paragraph);
        this.prefetchPinyinAndDefinition(this.props.fileName, this.props.data.words);
        // User is signed in.
      } else {
        console.log("user signing out");
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.fileName !== this.props.fileName || newProps.paragraph !== this.props.paragraph) {
      console.log("paragraph", newProps.paragraph);
      console.log("nextParagraph", newProps.nextParagraph);
      var newState = {}
      Object.keys(this.state).map(item => {
        newState[item] = null;
      });

      for (var i=0; i<this.props.data.words.length; i++) {
        this[`ref${i}`] = null;
      }

      newState["position"] = 0;
      newState.startTime = new Date();
      this.setState(newState);
      this.setupGuessDatabaseListener(firebase.auth().currentUser.uid, newProps.fileName, newProps.paragraph);
      this.prefetchPinyinAndDefinition(newProps.fileName, newProps.data.words);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.allCorrect && !this.state.endTime) {
      this.updateStartEndTime();
    }
  }

  componentWillUnmount() {
    this.unsubscribeFunction && this.unsubscribeFunction();
  }

  setupGuessDatabaseListener = (userId, fileName, paragraph) => {
    this.startEndTimePromise = firebase.firestore()
      .collection('users').doc(userId)
      .collection('files').doc(fileName)
      .collection('lines').doc(`${paragraph}`).get().then(docSnapshot => {
        console.log(docSnapshot.data())
        var newState = {};
        if (docSnapshot.get('startTime')) {
          newState.startTime = docSnapshot.get('startTime');
        }
        if (docSnapshot.get('endTime')) {
          newState.endTime = docSnapshot.get('endTime');
        }
        this.setState(newState);
        return newState;
    });

    this.startEndTimePromise.then(() => {
      delete this.startEndTimePromise;
    })

    console.log(`setting up guess database listener for /${userId}/${fileName}/${paragraph}`)
    this.unsubscribeFunction && this.unsubscribeFunction();
    this.unsubscribeFunction = firebase.firestore()
      .collection('users').doc(userId)
      .collection('files').doc(fileName)
      .collection('lines').doc(`${paragraph}`)
      .collection('words')
      .onSnapshot((snapshot) => {
        var newState = [];

        snapshot.docChanges().forEach((change) => {
            console.log("user word change", change.doc, change.doc.data());
            var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
            if (source == "Local") {
              return;
            }

            if (change.type === "added") {
              //console.log("New guess: ", change.doc.data());
              newState[`position${change.doc.id}`] = change.doc.data()['guess'];
              newState[`guess-${change.doc.id}-edited`] = false;
              newState[`showDefinition-${change.doc.id}`] = change.doc.get('revealedDefinition');
              newState[`revealedPinyin-${change.doc.id}`] = change.doc.get('revealedPinyin');
              newState[`customHint-${change.doc.id}`] = change.doc.get('customHint');
              if (change.doc.data()['guess'] === undefined) {
                newState[`error${change.doc.id}`] =  false;
                newState[`hint${change.doc.id}`] = "";
                newState[`correct${change.doc.id}`] = false;
              } else {
                this.updateCorrectStatus(change.doc.id, change.doc.data()['guess'], /*userInitiated*/ false, change.doc.get('customHint'));
              }
            }
            if (change.type === "modified") {
              //console.log("Modified guess: ", change.doc.data());
              newState[`position${change.doc.id}`] = change.doc.data()['guess'];
              newState[`guess-${change.doc.id}-edited`] = false;
              newState[`showDefinition-${change.doc.id}`] = change.doc.get('revealedDefinition');
              newState[`revealedPinyin-${change.doc.id}`] = change.doc.get('revealedPinyin');
              newState[`customHint-${change.doc.id}`] = change.doc.get('customHint');
              if (change.doc.data()['guess'] === undefined) {
                newState[`error${change.doc.id}`] =  false;
                newState[`hint${change.doc.id}`] = "";
                newState[`correct${change.doc.id}`] = false;
              } else {
                this.updateCorrectStatus(change.doc.id, change.doc.data()['guess'], /*userInitiated*/ false, change.doc.get('customHint'));
              }
            }
            if (change.type === "removed") {
              //console.log("Removed guess: ", change.doc.data());
              newState[`position${change.doc.id}`] = "";
              newState[`guess-${change.doc.id}-edited`] = false;
              newState[`correct${change.doc.id}`] = undefined;
              newState[`showDefinition-${change.doc.id}`] = undefined;
              newState[`revealedPinyin-${change.doc.id}`] = undefined;
              newState[`customHint-${change.doc.id}`] = undefined;
            }
        });

        this.setState(newState);
    });
  }

  prefetchPinyinAndDefinition = (fileName, words) => {
    console.log(`prefetching pinyin & definitions for ${words.length} words`);
    var alreadyFetched = {};
    for (var i=0; i<words.length; i++) {
      let word = words[i].word;
      if (word in alreadyFetched) {
        continue;
      } else {
        alreadyFetched[word] = true;
      }

      this[`prefetchPromise${word}`] = firebase.firestore()
        .collection('files').doc(fileName)
        .collection('words').doc(word)
        .get().then(wordSnapshot => {

          var newState = {};

          if (wordSnapshot.get("pinyin")) {
            let split = pinyinSplit(wordSnapshot.get("pinyin").toLowerCase());
            var pinyin = split.map(text => pinyinUtils.markToNumber(text));
            newState[`pinyin-${word}`] = pinyin;
          }

          if (wordSnapshot.get("translationWithPOS")) {
            newState[`definition-${word}`] = wordSnapshot.get("translationWithPOS");
          }

          if (wordSnapshot.data()) {
            newState[`word-${word}`] = wordSnapshot.data();
          }

          this.setState(newState);
          return newState;
        });

      this[`prefetchPromise${word}`].then(() => {
        delete this[`prefetchPromise${word}`];
      });
    }
  }

  onClick = () => {
  }

  setRef = (r, element) => {
    if (element === null) {
      console.log('skipping ref update for row', r)
    } else {
      this[`ref${r}`] = element;
      console.log("setting ref for row", r, element, element === null)
    }
  }

  updateCorrectStatus = (r, guessPinyin, userInitiated, customHint) => {
    if (this.props.data.words[r] === undefined) {
      console.log('updateCorrectStatus', r, guessPinyin, userInitiated);
    }
    var word = this.props.data.words[r].word;
    var errorKey = `error${r}`;
    var hintKey = `hint${r}`;

    var setNull = () => {
      this.setState({
          [errorKey]: false,
          [hintKey]: "",
          [`correct${r}`]: false,
        });
    }

    if (guessPinyin === undefined || guessPinyin === null) {
      return setNull();
    }

    guessPinyin = guessPinyin.toLowerCase();
    var errorValue = null;

    var comparePinyin = (correctPinyin) => {
      if (correctPinyin === null || correctPinyin === undefined) {
        return setNull();
      }

      //console.log(data);
      if (guessPinyin != correctPinyin) {
        errorValue = true;
      }

      let isCorrect = (correctPinyin == guessPinyin);

      if (guessPinyin !== undefined && guessPinyin.length > 0) {
        var distances = PinyinLevDis(guessPinyin, correctPinyin);
        var pinyinDistances = PinyinDis(guessPinyin, correctPinyin);

        var customHint = [];
        var allCorrect = true;
        for (var i=0; i<pinyinDistances.length; i++) {
          var hint = '';
          if (pinyinDistances[i].initial.isCorrect === false) {
            hint += pinyinDistances[i].initial.actual;
            allCorrect = false;
          }
          if (pinyinDistances[i].final.isCorrect === false) {
            hint += pinyinDistances[i].final.actual;
            allCorrect = false;
          }
          if (pinyinDistances[i].tone.isCorrect === false) {
            hint += pinyinDistances[i].tone.actual;
            allCorrect = false;
          }
          customHint.push(hint);
        }

        var currentCustomHint = this.state[`customHint-${r}`];
        console.log('allCorrect', allCorrect, currentCustomHint)
        if (!allCorrect && ((currentCustomHint === undefined) || currentCustomHint.length === 0)) {
          console.log('existing hint', currentCustomHint)
          console.log('new hint', customHint)
          this.updateUserWordRow(r, "customHint", customHint);
          this.setState({
            [`customHint-${r}`]: customHint,
          });
        }
        console.log('pinyinDistances', pinyinDistances, customHint);
      } else {
        var distances = [];
      }

      if (userInitiated === true) {
        this.recordGuess(word, guessPinyin, correctPinyin, isCorrect, r, this.isPinyinRevealed(r));
      }

      this.setState((prevState, props) => {
        return {
          [errorKey]: errorValue,
          [hintKey]: distances.join(","),
          [`correct${r}`]: isCorrect,
        }
      })
    }

    console.log(this[`prefetchPromise${word}`]);
    if (this[`prefetchPromise${word}`] !== undefined) {
      Promise.resolve(this[`prefetchPromise${word}`])
        .then(prefetch => comparePinyin(prefetch[`pinyin-${word}`].join('')));
    } else {
      comparePinyin(this.state[`pinyin-${word}`].join(''));
    }
  }

  handleBlur = (r) => {
    var guessPinyin = this.state[`position${r}`];
    if (this.state[`guess-${r}-edited`]) {
      this.updateCorrectStatus(r, guessPinyin, /* userInitiated: */ true, this.state[`customHint-${r}`]);
      this.setState({
        [`guess-${r}-edited`]: false,
      })
    }
  }

  onKeyPress = (r, e) => {
    if (e.key === '?') {
      e.preventDefault();
      if (this.isPinyinRevealed(r) || this.state[`correct${r}`]) {
        this.handleClickShowDefinition(r);
      } else {
        this.handleClickShowPronunciation(r);
      }
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      var direction = e.shiftKey ? -1 : 1;
      this.focusNext(r, direction);
    }
  }

  focusNext = (currentRow, direction) => {
    var newRow = currentRow;
    var newRef = null;
    while (newRef == null) {
      newRow += direction;

      if (newRow >= this.props.data.words.length) {
        console.log("already at the last word, not advancing");
        this.handleBlur(currentRow);
        return;
      } else if (newRow <= 0) {
        console.log("already at the first word, not advancing");
        this.handleBlur(currentRow);
        return;
      }

      newRef = this[`ref${newRow}`];
      console.log("checking row", newRow, newRef);
    }

    console.log(newRef);
    newRef.focus();
    this.setState({
      focusedRow: newRow,
    });
  }

  handleTextChange = (r, event) => {
    var value = event.target.value;
    value = value.replace(/v/g, 'ü');
    this.setState({
      [`position${r}`]: value,
      [`guess-${r}-edited`]: true,
      [`correct${r}`]: undefined,
    });

    this.updateUserWordRow(r, "guess", event.target.value);
  }

  updateStartEndTime = () => {
    // TODO: set up a database listner to listen for the start & end time on doc load
    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }

    var endTime = new Date();

    this.setState({
      endTime: endTime,
    })

    let update = () => {
      // console.log(`/users/${firebase.auth().currentUser.uid}/files/${this.props.fileName}/lines/${this.props.paragraph}`);
      let ref = firebase.firestore()
        .collection('users').doc(firebase.auth().currentUser.uid)
        .collection('files').doc(this.props.fileName)
        .collection('lines').doc(`${this.props.paragraph}`)
        .set({
          startTime: this.state.startTime,
          endTime: endTime,
        }, { merge: true });
    }

    if (this.startEndTimePromise === undefined) {
      update();
    } else {
      this.startEndTimePromise.then(newState => {
        if (!newState.endTime) {
          update();
        }
      });
    }
  }

  updateUserWordRow = (row, key, value) => {
    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }

    let ref = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('files').doc(this.props.fileName)
      .collection('lines').doc(`${this.props.paragraph}`)
      .collection('words').doc(`${row}`).set({
        [key]: value,
      }, { merge: true });
  }

  recordGuess = (word, guessPinyin, correctPinyin, correct, row, revealed) => {
    console.log(`recording guess`)

    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }

    let guessRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('words').doc(word)
      .collection('guesses').doc();

    guessRef.set({
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      guessPinyin: guessPinyin,
      correctPinyin: correctPinyin,
      correct: correct,
      revealed: revealed || false,
      document: this.props.fileName,
      paragraph: this.props.paragraph,
      wordIndex: row,
      word: word,
      characters: word.split('')
    });
  }

  recordReveal = (word, revealType, row) => {
    console.log(`recording reveal`)

    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }

    let revealRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('words').doc(word)
      .collection('reveals').doc();

    revealRef.set({
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: revealType,
      document: this.props.fileName,
      paragraph: this.props.paragraph,
      wordIndex: row,
      word: word,
      characters: word.split('')
    });
  }

  isPinyinRevealed(row) {
    return this.state[`revealedPinyin-${row}`] === true;
  }

  isDefinitionRevealed(row) {
    return this.state[`revealedDefinition-${row}`] === true;
  }

  handleClickShowPronunciation = r => {
    console.log("handleClickShowPronunciation");
    var word = this.props.data.words[r].word;
    this.updateUserWordRow(r, "revealedPinyin", true);

    this.setState({
      [`hint${r}`]: this.state[`pinyin-${word}`].join(''),
      [`revealedPinyin-${r}`]: true,
    });

    this.recordReveal(word, 'pronunciation', r);
  }

  handleClickShowDefinition = r => {
    this.setState({
        [`showDefinition-${r}`]: true,
      });

    this.updateUserWordRow(r, "revealedDefinition", true);
    var word = this.props.data.words[r].word;
    this.recordReveal(word, 'definition', r);
  }

  isKnownWord = word => {
    if (word in this.props.pronunciationKnownWords
       && word in this.props.definitionKnownWords) {
       return true;
     } else {
       return false;
     }
  }

  closeAllTooltips = () => {
    var newState = {};
    for (var r=0; r<this.props.data.words.length; r++) {
      newState[`wordTooltip-${r}-open`] = false;
    }
    this.setState(newState);
  }

  handleTooltipClose = r => {
    this.setState({ [`wordTooltip-${r}-open`]: false });
  };

  handleTooltipOpen = r => {
    if (!this.isDefinitionRevealed(r)) {
      this.handleClickShowDefinition(r);
    }

    console.log(`wordTooltip-${r}-open`, "true");
    this.setState({ [`wordTooltip-${r}-open`]: true });
  };

  render() {
    let classes = this.props.classes;
    let position = this.state.position;

    var highFrequencyWordsRevealed = {};

    var sections = [];
    var sectionSpans = [];
    var sectionText = "";
    var sectionNumber = 0;
    var rows = [];
    var allCorrect = true;
    for (var r=0; r<this.props.data.words.length; r++) {
      // character part
      let wordBundle = this.props.data.words[r];
      if (wordBundle === undefined) {
        continue
      }

      if (`word-${wordBundle.word}` in this.state) {
        let documentWord = this.state[`word-${wordBundle.word}`];
        let revealed = this.isPinyinRevealed(r);

        console.log('high frequency check', revealed, documentWord.count)
        if (revealed && documentWord.count > 2) {
          console.log(wordBundle.word, 'high frequency word revealed', documentWord.count);
          highFrequencyWordsRevealed[documentWord.word] = documentWord;
        }
      }


      var pinyinIfRevealed = '';
      var pronunciation = '';
      if (this.isPinyinRevealed(r) && this.state[`pinyin-${wordBundle.word}`] !== undefined) {
        console.log("pinyin is revealed")
        pinyinIfRevealed = this.state[`pinyin-${wordBundle.word}`];
        pronunciation = this.state[`pinyin-${wordBundle.word}`];
      } else {
        pronunciation = this.state[`customHint-${r}`];
      }


      sectionSpans.push(
        <TooltipWord
          key={r}
          r={r}
          handleClose={this.handleTooltipClose}
          open={this.state[`wordTooltip-${r}-open`] || false}
          title={this.state[`definition-${wordBundle.word}`] || '...'}
          pronunciation={pronunciation}
          word={wordBundle.word}
          handleTooltipOpen={this.handleTooltipOpen}
          />
      )

      sectionText += wordBundle.word;

      if (isPunctuation(wordBundle.word) || this.isKnownWord(wordBundle.word)) {
        rows.push(<div key={`row-${r}`}>{wordBundle.word}</div>)
      } else {
        var backgroundClass = undefined;
        if (this.state[`correct${r}`]) {
          backgroundClass = classes.correctTextField;
        } else if (this.state[`error${r}`]) {
          backgroundClass = classes.incorrectTextField;
          allCorrect = false;
        } else if (!this.state[`position${r}`]) {
          allCorrect = false;
        }

        var definitionIfShown = '';
        if (this.state[`showDefinition-${r}`] === true) {
           definitionIfShown = this.state[`definition-${wordBundle.word}`];
        }

        rows.push(
          <WordGuesserView
            key={`row-${r}`}
            r={r}
            word={wordBundle.word}
            pinyinIfRevaled={pinyinIfRevealed}
            definitionIfShown={definitionIfShown}
            hint={this.state[`hint${r}`]}
            guessPinyin={this.state[`position${r}`]}
            backgroundClass={backgroundClass}

            handleTextChange={this.handleTextChange}
            handleBlur={this.handleBlur}
            onKeyPress={this.onKeyPress}
            setRef={this.setRef}
            handleClickShowDefinition={this.handleClickShowDefinition}
            handleClickShowPronunciation={this.handleClickShowPronunciation}
          />)
      }

      if (wordBundle.word === "。" || r+1 === this.props.data.words.length) {
        sections.push({
          number: sectionNumber,
          spans: sectionSpans,
          text: sectionText,
          rows: rows
        });
        sectionNumber += 1;
        sectionSpans = [];
        sectionText = '';
        rows = [];
      }
    }

    this.allCorrect = allCorrect;

    var paragraphNotesRef = null;
    if (firebase.auth().currentUser !== null) {
      paragraphNotesRef = firebase.firestore()
       .collection('users').doc(firebase.auth().currentUser.uid)
       .collection('files').doc(this.props.fileName)
       .collection('paragraphNotes').doc(this.props.paragraph)
    }

    return (
      <div>
        {paragraphNotesRef &&
          <DatabaseTextField
            refPath={paragraphNotesRef.path}
            label="Paragraph Notes"
            margin="normal"
            fullWidth
           />
        }
      <div className={classes.reader}>
        {sections.map(section =>
          <div key={section.number} className={classes.readerSection}>
            {section.rows}
            <Typography variant="h5">
              <ClickAwayListener onClickAway={this.closeAllTooltips}>
                <div className={classes.sectionSpans}>
                  {section.spans}
                </div>
              </ClickAwayListener>
            </Typography>
            <UserTranslationTextField
              fileName={this.props.fileName}
              paragraph={this.props.paragraph.toString()}
              chunkKey={section.number.toString()}
              chunkText={section.text} />
          </div>
        )}
      </div>
        <HighFrequencyReview
          documentWordBundles={highFrequencyWordsRevealed}
        />
        <ParagraphSummary
          fileName={this.props.fileName}
          lineNumber={this.props.paragraph}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          />

        <Card className={classes.card}>
         <CardContent>
           <Typography variant="h5" component="h2">
             Goal Progress (fake)
           </Typography>
           <Typography component="h2">
           35%
           </Typography>
           <Typography component="p">
           Only 2 paragraphs to go!
           </Typography>
         </CardContent>
         <CardActions>
           <Button size="small">Review Goals</Button>
         </CardActions>
        </Card>

        <ParagraphPreview
          fileName={this.props.fileName}
          lineNumber={this.props.nextParagraph}
          />

      </div>
    )
  }
});

export default Reader;
