import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
require('firebase/firestore');

const styles = theme => ({
  card: {
    marginBottom: '8px',
  },
})

const ParagraphPreview = withStyles(styles)(withRouter(class extends Component {
  state = {
    paragraph: null,
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        this.setupDatabaseListener(user.uid, this.props.fileName, this.props.lineNumber)
        // User is signed in.
      } else {
        console.log("user signing out");
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.lineNumber != this.props.lineNumber) {
      var newState = {}
      Object.keys(this.state).map(item => {
        newState[item] = null;
      });

      this.setState(newState);
      this.setupDatabaseListener(firebase.auth().currentUser.uid, newProps.fileName, newProps.lineNumber);
    }
  }

  componentWillUnmount() {
  }

  setupDatabaseListener = (userId, fileName, lineNumber) => {
    console.log(`setting up paragraph preview database listener for /${fileName}/${lineNumber}`)
    firebase.firestore()
      .collection('files').doc(fileName)
      .collection('lines').doc(`${lineNumber}`)
      .get().then(docSnapshot => {
        if (!docSnapshot.exists) {
          this.setState({
            paragraph: null,
          });
          return;
        }

        console.log("paragraph");
        console.log(docSnapshot.data())
        this.setState({
          paragraph: docSnapshot.data(),
        });

      });
  }

  estimatedMinutes = () => {
    if (this.state.paragraph === null
      || this.state.paragraph === undefined) {
        return null;
      }

    // this is a rough estimate based on my experience with the spreadsheet
    let wordsPerHour = 400;
    let minutesPerHour = 60;

    let estimatedMinutes =
      this.state.paragraph.words.length / wordsPerHour * minutesPerHour;

    return estimatedMinutes.toFixed(0);
  }


  render() {
    let classes = this.props.classes;

    return (
      <Card className={classes.card}>
       <CardContent>
         <Typography variant="h5" component="h2">
           Paragraph {this.props.lineNumber} Preview
         </Typography>
         <Typography component="p">
           {`Estimated Time: ${this.estimatedMinutes()} minutes`}
         </Typography>
         <Typography component="p">
           "New Words: 15 (fake)"
         </Typography>
       </CardContent>
       <CardActions>
         <Button
            size="small"
            color="primary"
            onClick={() => { this.props.history.push(`/d/${this.props.fileName}/p/${this.props.lineNumber}`) }}
            >
           Study</Button>
         <Button size="small">Review New Words (fake))</Button>
       </CardActions>
      </Card>
    );
  }
}));

export default ParagraphPreview;
