import React, { PureComponent } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Word from '../Word.js';

class TooltipWord extends PureComponent {
  handleClose = () => {
    if (this.props.r !== undefined) {
      this.props.handleClose && this.props.handleClose(this.props.r);
    }
  }

  handleTooltipOpen = () => {
    if (this.props.r !== undefined) {
      this.props.handleTooltipOpen && this.props.handleTooltipOpen(this.props.r);
    }
  }


  render() {
    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={this.handleClose}
        open={this.props.open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        title={this.props.title}
      >
        <Word pronunciation={this.props.pronunciation} word={this.props.word} onClick={this.handleTooltipOpen} />
      </Tooltip>
    )
  }
}

export default TooltipWord;
