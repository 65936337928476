import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';


import firebase from 'firebase';

import Word from '../Word';
import Reader from '../Reader';
import LearnedWordWatcher from '../widgets/LearnedWordWatcher';

require('firebase/firestore')



const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '800px',
    width: 'calc(100% - 16px)',
    paddingTop: 'calc(64px + 10px)', // header is 64px
    paddingBottom: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 288,
  },
    menu: {
    width: 288,
  },
});

const BookPrepPage = withRouter(class extends Component {
  state = {
    fileNames: [],
    paragraphs: [],
    paragraphData: {},
    currentFileName: null,
    signedIn: false,
    definitionKnownWords: {},
    pronunciationKnownWords: {},
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signedIn: true,
        });
        if (this.props.match.params.docId && this.props.match.params.paragraphId) {
          this.loadFile(this.props.match.params.docId, this.props.match.params.paragraphId);
        } else {
          this.loadMostRecentPage(user);
        }
        this.setupKnownWordsListener(user.uid);
        // User is signed in.
      } else {
        this.setState({
          signedIn: false,
        });
        // No user is signed in.
      }
    });

    console.log("fetching");
    let fileNamesRef = firebase.firestore()
                .collection('files').orderBy('filename').get();
    fileNamesRef.then(querySnapshot => {
      var fileNames = [];

      querySnapshot.forEach(doc => {
        fileNames.push(doc.id);
        // console.log(doc.id, " => ", doc.data());
      });

      this.setState({
        fileNames: fileNames,
      });
    });
  }

  componentWillUpdate(nextProps, nextState) {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.docId != prevProps.match.params.docId || this.props.match.params.paragraphId != prevProps.match.params.paragraphId) {
      this.loadFile(this.props.match.params.docId, this.props.match.params.paragraphId);
      window.scrollTo(0, 0);
    }
  }

  loadFile = (fileName, paragraph) => {
    console.log(`setting file to "${fileName}" and paragraph to "${paragraph}"`);
    this.setState({
      paragraphs: [],
      paragraphData: null,
      currentFileName: null,
      paragraph: null,
    });

    let linesRef = firebase.firestore()
                .collection('files').doc(fileName)
                .collection('lines').orderBy('line').get();
    linesRef.then(querySnapshot => {
      var paragraphs = [];
      var paragraphData = {};

      querySnapshot.forEach(doc => {
        paragraphs.push(doc.id);
        paragraphData[doc.id] = doc.data();
        // console.log(doc.id, " => ", doc.data());
      });

      this.setState({
        paragraphs: paragraphs,
        paragraphData: paragraphData,
        currentFileName: fileName,
        currentParagraph: paragraph || paragraphs[0],
      });

      if (!firebase.auth().currentUser.uid) {
        console.warn("user isn't logged in");
        return;
      }

      firebase.firestore()
        .collection('users').doc(firebase.auth().currentUser.uid)
        .set({
          'mostRecentFileName': fileName,
          'mostRecentParagraph': paragraph || paragraphs[0],
        }, { merge: true });
    });
  }

  loadMostRecentPage = user => {
    firebase.firestore()
      .collection('users').doc(user.uid)
      .get().then(userSnapshot => {
        var fileName = userSnapshot.get('mostRecentFileName');
        var paragraph = userSnapshot.get('mostRecentParagraph');
        console.log('userSnapshot', userSnapshot.data());
        if (fileName !== null && paragraph != null) {
          this.loadFile(fileName, paragraph);
          console.log('updated user\'s state')
        } else {
          this.loadFile('harrypotter-chapter3.txt');
        }
      });
  }

  setupKnownWordsListener = userId => {
    this.unsubscribePronunciationKnownFunction && this.unsubscribePronunciationKnownFunction();
    this.unsubscribePronunciationKnownFunction = firebase.firestore()
      .collection('users').doc(userId)
      .collection('words').where('pronunciationKnown', '>=', 0.8)
      .onSnapshot(snapshot => {
        var pronunciationKnownWords = {};
        snapshot.forEach(docSnapshot => {
          var word = docSnapshot.id;
          pronunciationKnownWords[word]
            = docSnapshot.get('pronunciationKnown');
        });
        console.log('pronunciationKnown', pronunciationKnownWords);
        this.setState({
          pronunciationKnownWords: pronunciationKnownWords,
        });
    });

    this.unsubscribeDefinitionKnownFunction && this.unsubscribeDefinitionKnownFunction();
    this.unsubscribeDefinitionKnownFunction = firebase.firestore()
      .collection('users').doc(userId)
      .collection('words').where('definitionKnown', '>=', 0.8)
      .onSnapshot(snapshot => {
        var definitionKnownWords = {};
        snapshot.forEach(docSnapshot => {
          var word = docSnapshot.id;
          definitionKnownWords[word]
            = docSnapshot.get('definitionKnown');
        });
        console.log('definitionKnownWords', definitionKnownWords);
        this.setState({
          definitionKnownWords: definitionKnownWords,
        });
    });
  }

  setParagraph = paragraph => {
    this.props.history.push(`/d/${this.state.currentFileName}/p/${paragraph}`)

    if (!firebase.auth().currentUser.uid) {
      console.warn("user isn't logged in");
      return;
    }


    firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .set({
        'mostRecentParagraph': paragraph,
      }, { merge: true });
  }

  returnToPreviousParagraph = () => {
    var paragraphPosition = this.state.paragraphs.indexOf(this.state.currentParagraph);
    this.setState({
      currentParagraph: this.state.paragraphs[paragraphPosition - 1],
    })
    window.scrollTo(0, 0);
  }

  advanceToNextParagraph = () => {
    var paragraphPosition = this.state.paragraphs.indexOf(this.state.currentParagraph);
    var nextParagraph = this.state.paragraphs[paragraphPosition + 1];
    this.props.history.push(`/d/${this.state.currentFileName}/p/${nextParagraph}`)
    window.scrollTo(0, 0);
  }

  handleChange = (name, transform) => event => {
    var value = event.target.value;
    if (typeof(transform) === 'function') {
      value = transform(value);
    }
    console.log(`${name} => ${value}`)
    this.setState({
      [name]: value,
    });
  };

  logOut = () => {
    firebase.auth().signOut();
  }

  getSummary = (paragraph) => {
    var summary = "";
    var summaryLength = 6;
    var words = this.state.paragraphData[paragraph].words;
    for (var i=0; i<words.length; i++) {
      var word = words[i].word;
      if (summary.length > summaryLength) {
        break;
      }
      for (var j=0; j<word.length; j++) {
        var character = word[j];
        if (summary.length > summaryLength) {
          break;
        }
        summary += character;
      }
    }

    if (this.state.paragraphData[paragraph].words.length > summaryLength) {
      summary += "...";
    }

    return summary;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        <Helmet title={`Dokibo BookPrep`} />
        <AppBar color='default' position="fixed">
          <Toolbar>
            <Typography type="title" color="inherit" className={classes.grow}>
              Dokibo BookPrep
            </Typography>
            {this.state.signedIn ?
            <div>
              <span>{firebase.auth().currentUser.displayName}</span>
              <Button color="inherit" onClick={this.logOut}>Log out</Button>
            </div>
            :
            <Button component={Link} to="/login" color="inherit">Sign in</Button>
            }
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <div>
            <Typography type="title">
              Paragraph (<a href="/d/harrypotter-chapter3.txt/p">see all</a>)
            </Typography>
          </div>
          <TextField
            id="select-paragraph"
            select
            className={classes.textField}
            value={this.state.currentParagraph}
            onChange={e => this.setParagraph(e.target.value)}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
          {this.state.paragraphs.length == 0 ?
            <option value="loading">loading...</option>
            :
            this.state.paragraphs.map((paragraph, index) => (
            <option key={paragraph} value={paragraph}>
              {paragraph}: {this.getSummary(paragraph)}
            </option>
          ))}
          </TextField>


          {this.state.currentFileName !== null
            && this.state.currentParagraph !== null
            &&
            <Reader
              fileName={this.state.currentFileName}
              paragraph={this.state.currentParagraph}
              nextParagraph={this.state.paragraphs[this.state.paragraphs.indexOf(this.state.currentParagraph)+1]}
              data={this.state.paragraphData[this.state.currentParagraph]}
              pronunciationKnownWords={this.state.pronunciationKnownWords}
              definitionKnownWords={this.state.definitionKnownWords}
               />
          }

          <LearnedWordWatcher />
        </div>
      </div>
    );
  }
});

// <Word onClick={this.onClickWord} key={index} token={token} className={classes.word} />


BookPrepPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookPrepPage);
